import React from "react";
import { Link } from "gatsby";

const AboutBlog = () => {
  return (
    <div className="about-blog">
      <div className="about-blog__content">
        <div className="about-blog__image-container">
          {" "}
          <img src={require("../assets/bente-butikk.jpg")} />
        </div>
        <div className="about-blog__text-container">
          <h4>Om bloggen</h4>
          <p>
            Jeg vil bruke bloggen til å komme med tips til en sunnere hverdag på en lettfattelig
            måte, enkelt og greit!
          </p>

          <Link to="/blog/2020/11/om-bloggen/">Les mer her</Link>
        </div>
      </div>
    </div>
  );
};

export default AboutBlog;
