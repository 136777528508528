import React from "react";
import HeroNoImage from "../components/hero-no-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PortableText from "../components/portableText";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
// import {
//   mapEdgesToNodes,
//   filterOutDocsWithoutSlugs,
//   filterOutDocsPublishedInTheFuture
// } from "../lib/helpers";
import Container from "../components/container";
import BlogPostPreviewGrid from "../components/blog-post-preview-grid";
import GraphQLErrorList from "../components/graphql-error-list";

import "../styles/layout.scss";
import AboutBlog from "../components/about-blog";

export const query = graphql`
  query AboutPageQuery {
    posts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    about: sanityAbout {
      _rawAboutMe
      title
      mainImage {
        asset {
          _id
        }
        alt
      }
    }
  }
`;

const ContactPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const aboutData = (data || {}).about;

  // const postNodes = (data || {}).posts
  //   ? mapEdgesToNodes(data.posts)
  //       .filter(filterOutDocsWithoutSlugs)
  //       .filter(filterOutDocsPublishedInTheFuture)
  //   : [];

  return (
    <Layout>
      <SEO title="Om-meg" />
      <HeroNoImage />
      <Container>
        <div className="about-page">
          <h1>{aboutData.title}</h1>
          <div className="about-page__flex">
            <div className="about-page__text-container">
              {aboutData && <PortableText blocks={aboutData._rawAboutMe} />}
            </div>
            <div className="about-page__image-container">
              <img
                src={imageUrlFor(buildImageObj(aboutData.mainImage))
                  .width(500)
                  .height(Math.floor((9 / 16) * 900))
                  .fit("crop")
                  .auto("format")
                  .url()}
                alt={aboutData.mainImage.alt}
              />
              {/* <img src={require("../assets/garden.jpg")} alt="Bente Grove" /> */}
            </div>
          </div>
        </div>
        {/* <div className="blog-preview-home">
          {postNodes && (
            <BlogPostPreviewGrid
              title="Siste blogg innlegg"
              nodes={postNodes}
              browseMoreHref="/blog/"
              page={"index"}
            />
          )}
        </div> */}
      </Container>
      <AboutBlog />
    </Layout>
  );
};

export default ContactPage;
